* {
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: 'Outfit';
}

main{
    overflow-x: hidden;
}

body::-webkit-scrollbar, div::-webkit-scrollbar, main::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    transition: width 0.15s linear;
}

body::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb, main::-webkit-scrollbar-thumb {
    background-color: #d8dce1;
    border-radius: 4px;
    transition: background-color 0.15s linear;
}

body::-webkit-scrollbar-thumb:hover, div::-webkit-scrollbar-thumb:hover, main::-webkit-scrollbar-thumb:hover {
    background-color: #EAECEF;
}

h1 {
    font: normal normal bold 40px/50px Outfit;
}

h2 {
    font: normal normal bold 40px/45px Outfit;
}

h3 {
    font: normal normal 300 24px/30px Outfit;
}

h4 {
    font: normal normal bold 14px/20px Outfit;
}

h5 {
    font: normal normal normal 11px/20px Outfit;
}

@media (min-width: 768px) {
    h1 {
        font: normal normal bold 60px/68px Outfit;
    }
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Thin.ttf');
    font-weight: 100;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-ExtraLight.ttf');
    font-weight: 200;
    
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-ExtraBold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Outfit';
    src: url('../assets/fonts/Outfit-Black.ttf');
    font-weight: 900;
}
